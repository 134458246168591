import {SelectItem} from 'primeng/api';
import { ProgramsFilter } from '../models/program';

export class SetSelectedFilter {
  public static readonly type = '[Programs] SetSelectedFilter';
  constructor(public filter: ProgramsFilter) { }
}

export class SetProgramTypes {
  public static readonly type = '[Programs] SetProgramTypes';
  constructor(public programTypes: SelectItem[]) { }
}

export class SetCountries {
  public static readonly type = '[Programs] SetCountries';
  constructor(public countries: SelectItem[]) { }
}

export class SetLocationTypes {
  public static readonly type = '[Programs] SetLocationTypes';
  constructor(public locationTypes: SelectItem[]) { }
}

export class SetRDORegions {
  public static readonly type = '[Programs] SetRDORegions';
  constructor(public regions: SelectItem[]) { }
}

export class SetGlobalRegions {
  public static readonly type = '[Programs] SetGlobalRegions';
  constructor(public globalRegions: SelectItem[]) { }
}

export class SetLanguages {
  public static readonly type = '[Programs] SetLanguages';
  constructor(public languages: SelectItem[]) { }
}
