import { SelectItem } from "primeng/api";
import { HousingFilter } from "../models/housing";

export class SetFilter {
  public static readonly type = '[Housing] SetFilter';
  constructor(public filter: HousingFilter) { }
}

export class SetHousingTypes {
  public static readonly type = '[Housing] SetHousingTypes';
  constructor(public data: SelectItem[]) { }
}

export class SetHousingLevels {
  public static readonly type = '[Housing] SetHousingLevels';
  constructor(public data: SelectItem[]) { }
}

export class SetReloadHousingTypeData {
  public static readonly type = '[Housing] SetReloadHousingTypeData';
  constructor() { }
}

export class SetReloadHousingLevelData {
  public static readonly type = '[Housing] SetReloadHousingLevelData';
  constructor() { }
}

export class SetReloadHousingLevelListData {
  public static readonly type = '[Housing] SetReloadHousingLevelListData';
  constructor() { }
}

export class SetReloadHousingRankingListData {
  public static readonly type = '[Housing] SetReloadHousingRankingListData';
  constructor() { }
}
