import { Selector } from '@ngxs/store';
import { AgreementsStateModel } from '../models/agreement';
import { AgreementsState } from './agreements.state';

export class AgreementsSelectors {
  @Selector([AgreementsState])
  public static getFilter(state: AgreementsStateModel) {
    return state.filter;
  }
}
