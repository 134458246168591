import { Selector } from '@ngxs/store';
import { CensusStateModel } from './census-state.model';
import { CensusState } from './census.state';


export class CensusSelectors {
  @Selector([CensusState])
  static getSelectedProgramDetail(state: CensusStateModel) {
    return state.selectedProgramDetail;
  }

  @Selector([CensusState])
  static getSelectedProgramDetailOnlineOnly(state: CensusStateModel) {
    return state.selectedProgramDetailOnlineOnly;
  }

  @Selector([CensusState])
  static getSelectedProgramDetailDelayedOnsiteStartDate(state: CensusStateModel) {
    return state.selectedProgramDetailDelayedOnsiteStartDate;
  }

  @Selector([CensusState])
  static getSelectedProgramDetailEarlyOnsiteDepartureDate(state: CensusStateModel) {
    return state.selectedProgramDetailEarlyOnsiteDepartureDate;
  }

  @Selector([CensusState])
  static getSelectedProgramDetailStartDate(state: CensusStateModel) {
    return state.selectedProgramDetailStartDate;
  }
  
  @Selector([CensusState])
  static getSelectedProgramDetailEndDate(state: CensusStateModel) {
    return state.selectedProgramDetailEndDate;
  }

  @Selector([CensusState])
  public static getState(state: CensusStateModel) {
    return state;
  }
}
