import { Selector } from '@ngxs/store';
import { SettingsState } from './settings.state';
import { SettingsStateModel } from './settings-state.model';


export class SettingsSelectors {
  @Selector([SettingsState])
  static getNeedRefreshingMessageType(state: SettingsStateModel) {
    return state.needRefreshingMessageType;
  }

  @Selector([SettingsState])
  public static getState(state: SettingsStateModel) {
    return state;
  }

}
