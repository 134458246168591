import { Injectable } from '@angular/core';
import { CanActivate, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@/core/core.barrel';
import { Observable, EMPTY, of } from 'rxjs';
import { map, concatMap, switchMap } from 'rxjs/operators';

@Injectable({
  'providedIn': 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService
  ) {  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map(res => {
        if (!res) {
          this.authService.login(state.url);
          res = false;
        }

        return res;
      }),
      switchMap(res => {
        if (!res) {
          return of(res);
        }

        return this.authService.isRoleAuthorized(next.data.allowedRoles).pipe(map(roleAuthorized => {
          if (!roleAuthorized) {
            this.authService.accessDenied({ error: { status: 403 } });
            return false;
          }
      
          return true;
        }));
      })
    );
  }
}
