import { toDate, asUTCDate } from "@/shared/classes/utils";
import { TemplateAssignment } from "@/shared/models/shared";
import { SelectItem } from "primeng/api";

export class Program {
  id: number;
  name: string;
  programType: string;
  programTypeCode: string;
  programTypeId: number;
  isActive: boolean;
  locations: Array<string>;
  locationsLabel: string;
  readyToPublishToStaging:boolean;
  readyToPublishToProduction: boolean;
  programDetailsReadyToPublishToStaging: boolean;
  programDetailsReadyToPublishToProduction:boolean;
  createdBy: string;
  createdOn: Date;

  public static isProgram(obj: any): obj is Program
  {
    return (obj as Program).programType ? true : false;
  }
}

export class ProgramEditModel {
  id: number;
  name: string;
  fullName: string;
  marketingName: string;
  abbreviation: string;
  description: string;
  isActive: boolean;
  hideFromProgramSelector: boolean;
  hideFromProgramChoices: boolean;
  programTypeId: number;
  programCategoryId: number;
  sponsoringInstitution: string;
  sponsoringInstitutionId: number;
  webSiteUrl: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  squareImageUrl: string;
  locations: Array<SelectItem> = [];
  termCombinations: Array<SelectItem> = [];
  readings: Array<Reading> = [];
}

export class Reading {
  id: number;
  name: string;
  description: string;
  location: string;
  year: number;
  calendarSeason: string;
  calendarSeasonId: number;
  isHighImportance: boolean = false;
  isCourseReading?: boolean;
  applicationStatuses: Array<SelectItem> = [];
  applicationStatusesLabel: string;
  isReadonly: boolean = false;

  public static formatApplicationStatuses(model: Reading) {
    if (!model.applicationStatuses) {
      return;
    }

    model.applicationStatusesLabel = model.applicationStatuses.map(x => x.label).join(', ') || '';
    if (model.applicationStatusesLabel.length <= 20) {
      return;
    }

    model.applicationStatusesLabel = model.applicationStatusesLabel.substring(0, 17) + '...';
  }
}

export class ProgramDetail {
  id: number;
  program: string;
  programId: number;
  programType: string;
  programTypeId: number;
  season: string;
  seasonId: number;
  year: number;
  isActive: boolean;
  locations: Array<string>;
  locationsLabel: string;
  editing?: boolean;
  deleting?: boolean;
  createdBy: string;
  createdOn: Date;
}

export class ProgramDetailEditModel {
  id: number;
  programId: number;
  programTypeId: number;
  programSponsoringInstitutionId: number;

  program: string;
  programType: string;
  programLocations: Array<SelectItem> = [];
  courseHostInstitutions: Array<SelectItem> = [];
  programLocationsLabel: string;

  year: number;
  isActive: boolean;
  isModular: boolean;
  onlineOnly: boolean;
  seasonId: number;
  programLocationId: number;
  hasOnlineCourse: boolean;
  tuition: number;
  tuitionOverride: number;

  capacity: number;
  minimumParticipants: number;
  budgetedParticipants: number;
  atCapacity: boolean;

  applicationDeadlineDate: Date;
  applicationDeadlineExtensionDate: Date;
  tentativeStartDate: Date;
  startDate: Date;
  delayedOnsiteStartDate: Date;
  tentativeEndDate: Date;
  endDate: Date;
  earlyOnsiteDepartureDate: Date;
  courseSelectionDeadlineDate: Date;
  courseWithdrawalDate: Date;
  addDropDeadlineDate: Date;
  displayCourseAvailabilityDate: Date;
  academicProjectTaskDeadlineDate: Date;

  quarterHours: string;
  semesterHours: string;
  requiredGPA: number;
  minimumNumberOfCourses: number;
  maximumNumberOfCourses: number;
  minimumNumberOfCredits: number;
  requiredNumberOfAlternateCourseSelections: number;

  programDirectorId: number;
  programDirector: string;

  residentDirectorId: number;
  residentDirector: string;

  enrollmentAdvisorId: number;
  enrollmentAdvisor: string;

  admissionsCoordinatorId: number;
  admissionsCoordinator: string;

  universityStudyAbroadAdvisorId: number;
  universityStudyAbroadAdvisor: string;

  optionalInternshipOffered: boolean;
  internshipLanguages: string;
  hasApplicationDetails: boolean;

  programLeaders: Array<SelectItem> = [];
  templateAssignments: Array<TemplateAssignment> = [];
  programReadings: Array<Reading> = [];
  readings: Array<Reading> = [];

  public static formatDates(model: ProgramDetailEditModel) {
    model.applicationDeadlineDate = asUTCDate(model.applicationDeadlineDate);
    model.applicationDeadlineExtensionDate = asUTCDate(model.applicationDeadlineExtensionDate);
    model.tentativeStartDate = toDate(model.tentativeStartDate);
    model.startDate = toDate(model.startDate);
    model.delayedOnsiteStartDate = toDate(model.delayedOnsiteStartDate);
    model.tentativeEndDate = toDate(model.tentativeEndDate);
    model.endDate = toDate(model.endDate);
    model.earlyOnsiteDepartureDate = toDate(model.earlyOnsiteDepartureDate);
    model.courseSelectionDeadlineDate = toDate(model.courseSelectionDeadlineDate);
    model.courseWithdrawalDate = toDate(model.courseWithdrawalDate);
    model.addDropDeadlineDate = toDate(model.addDropDeadlineDate);
    model.displayCourseAvailabilityDate = toDate(model.displayCourseAvailabilityDate);
    model.academicProjectTaskDeadlineDate = toDate(model.academicProjectTaskDeadlineDate);
  }
}

export interface ProgramsStateModel {
  filter: ProgramsFilter;
  programTypes: SelectItem[];
  countries: SelectItem[];
  locationTypes: SelectItem[];
  regions: SelectItem[];
  globalRegions: SelectItem[];
  languages: SelectItem[];
}

export class ProgramsFilter {
  name: string;
  isActive: boolean;
  programTypeIds: Array<number>;
  programLocationIds: Array<number>;
  seasonIds: Array<number>;
  years: Array<number>;
  countryIds: Array<number>;
  modifiedBy: string;
}

export class EditReadingModel {
  reading: Reading;
  isUpdate: boolean;
}

export enum ProgramTableID {
  ProgramDetailTable,
  ProgramLocationTable
}

export enum ProgramReadingType {
  Program,
  ProgramDetail,
  ProgramLocation
}
