import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ciee-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

}
