import { Selector } from '@ngxs/store';
import { ProgramsStateModel } from '../models/program';
import { ProgramsState } from './programs.state';

export class ProgramsSelectors {
  @Selector([ProgramsState])
  public static getFilter(state: ProgramsStateModel) {
    return state.filter;
  }

  @Selector([ProgramsState])
  public static getProgramTypes(state: ProgramsStateModel) {
    return state.programTypes;
  }

  @Selector([ProgramsState])
  public static getCountries(state: ProgramsStateModel) {
    return state.countries;
  }

  @Selector([ProgramsState])
  public static getLocationTypes(state: ProgramsStateModel) {
    return state.locationTypes;
  }

  @Selector([ProgramsState])
  public static getRegions(state: ProgramsStateModel) {
    return state.regions;
  }

  @Selector([ProgramsState])
  public static getGlobalRegions(state: ProgramsStateModel) {
    return state.globalRegions;
  }

  @Selector([ProgramsState])
  public static getLanguages(state: ProgramsStateModel) {
    return state.languages;
  }


}
