import { Selector } from '@ngxs/store';
import { ApplicationPhotoReportState, ApplicationPhotoReportStateModel } from './application-photo.state';


export class ApplicationPhotoReportSelectors {
  @Selector([ApplicationPhotoReportState])
  public static getState(state: ApplicationPhotoReportStateModel) {
    return state;
  }

  @Selector([ApplicationPhotoReportState])
  public static getFormsDocumentSearchFilters(state: ApplicationPhotoReportStateModel) {
    return state.formsDocumentFilter;
  }
}
