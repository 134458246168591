import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../services/auth.service';


@Injectable({ 'providedIn': 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private logger: NGXLogger, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {

          if (error instanceof HttpErrorResponse && [401, 403].indexOf(error.status) !== -1) {
            this.authService.accessDenied(error);
          }

          //if (!environment.production) {
          //  window.alert(error.message);
          //}

          return throwError(error);
        })
      );
  }
}
