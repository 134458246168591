import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { ProgramsFilter, ProgramsStateModel } from '../models/program';
import { SetCountries, SetGlobalRegions, SetLanguages, SetLocationTypes, SetProgramTypes, SetRDORegions, SetSelectedFilter } from './programs.actions';

@State<ProgramsStateModel>({
  'name': 'programs',
  'defaults': {
    filter: new ProgramsFilter(),
    programTypes: [],
    countries: [],
    locationTypes: [],
    regions: [],
    globalRegions: [],
    languages: []
  }
})
@Injectable()
export class ProgramsState {

  @Action(SetSelectedFilter)
  public setSelectedFilter(ctx: StateContext<ProgramsStateModel>, action: SetSelectedFilter) {
    ctx.patchState({
      filter: action.filter
    });
  }

  @Action(SetProgramTypes)
  public SetProgramTypes(ctx: StateContext<ProgramsStateModel>, action: SetProgramTypes) {
    ctx.patchState({
      programTypes: [...action.programTypes],
    });
  }

  @Action(SetCountries)
  public SetCountries(ctx: StateContext<ProgramsStateModel>, action: SetCountries) {
    ctx.patchState({
      countries: [...action.countries],
    });
  }

  @Action(SetLocationTypes)
  public SetLocationTypes(ctx: StateContext<ProgramsStateModel>, action: SetLocationTypes) {
    ctx.patchState({
      locationTypes: [...action.locationTypes],
    });
  }

  @Action(SetRDORegions)
  public SetRDORegions(ctx: StateContext<ProgramsStateModel>, action: SetRDORegions) {
    ctx.patchState({
      regions: [...action.regions],
    });
  }

  @Action(SetGlobalRegions)
  public SetGlobalRegions(ctx: StateContext<ProgramsStateModel>, action: SetGlobalRegions) {
    ctx.patchState({
      globalRegions: [...action.globalRegions],
    });
  }

  @Action(SetLanguages)
  public SetLanguages(ctx: StateContext<ProgramsStateModel>, action: SetLanguages) {
    ctx.patchState({
      languages: [...action.languages],
    });
  }
}
