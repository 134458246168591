import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
    template: `
    <fullscreen-shell>
        <ng-template>
            <ciee-loading [loading] = "true" [externalClasses]="'tw-my-64'"></ciee-loading>
        </ng-template>
    </fullscreen-shell>
    `
})
export class AuthCallbackComponent {
    constructor(private authService: AuthService) { }

    ngOnInit() {
        this.authService.completeAuthentication();
    }
}
