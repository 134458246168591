export class SetSelectedProgramDetail {
  public static readonly type = '[Census] Set Selected Programdetail';
  constructor(public id: number) { }
}

export class SetSelectedProgramDetailOnlineOnly {
  public static readonly type = '[Census] Set Selected ProgramDetail OnlineOnly';
  constructor(public onlineOnly: boolean) { }
}

export class SetSelectedProgramDetailDelayedOnsiteStartDate {
  public static readonly type = '[Census] Set Selected ProgramDetail DelayedOnsiteStartDate';
  constructor(public delayedOnsiteStartDate: Date) { }
}

export class SetSelectedProgramDetailEarlyOnsiteDepartureDate {
  public static readonly type = '[Census] Set Selected ProgramDetail EarlyOnsiteDepartureDate';
  constructor(public earlyOnsiteDepartureDate: Date) { }
}

export class SetSelectedProgramDetailStartDate {
    public static readonly type = '[Census] Set Selected Programdetail StartDate';
    constructor(public date: Date) { }
  }
  
export class SetSelectedProgramDetailEndDate {
  public static readonly type = '[Census] Set Selected Programdetail EndDate';
  constructor(public date: Date) { }
}