import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { SettingsStateModel } from './settings-state.model';
import { SetNeedRefreshingMessageType } from './settings.actions';

@State<SettingsStateModel>({
  'name': 'settings',
  'defaults': {
    'needRefreshingMessageType': false
  }
})
@Injectable()
export class SettingsState {

  @Action(SetNeedRefreshingMessageType)
  public setNeedRefreshingMessageType(ctx: StateContext<SettingsStateModel>, action: SetNeedRefreshingMessageType) {
    ctx.patchState({
      'needRefreshingMessageType': action.needRefreshingMessageType
    });
  }

}
