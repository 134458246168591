import { Injectable } from "@angular/core";
import { Selector, State } from "@ngxs/store";
import { AuthState, AuthStateModel } from ".";

@Injectable()
export class AuthStateSelectors {
    
    @Selector([AuthState])
    static getShadowedUser(state: AuthStateModel) {
        return state.shadowedStaffState;
    }

    @Selector([AuthState])
    static getShadowedUserName(state: AuthStateModel) {
        return state.shadowedStaffState.name;
    }
}