import { Filter } from '@/core/models/filter';
import { SearchCriteria } from '@/core/models/searchCriteria';

export class ViewApplicationPhotoReport {
  public static readonly type = '[ApplicationPhotoReport] ViewApplicationPhotoReport';
  constructor(public programDetailIds: number[], public applicationStatusIds: number[]) { }
}

export class SetFormsDocumentFilter {
  public static readonly type = '[FormsDocumentsTemplate] SetFormsDocumentFilter';
  constructor(public searchCriteria: SearchCriteria[]) { }
}
