import { Inject, Injectable, InjectFlags } from '@angular/core';
import { DataService } from '@/core/services/data.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { MessageType } from './models/messageType';
import { Flag } from './models/flag';
import { FeeType } from './models/feeType';
import { HelpLink } from './models/helpLink';
import { PaginationRequest } from '@/core/core.barrel';
import { ProgramTypeService } from '@/programs/services/program-type.service';
import { StatusChangeReason } from './models/statusChangeReason';
import { SelectItem } from 'primeng/api';
import { map } from 'rxjs/operators';
import { LookupItem } from '@/shared/models/shared';


@Injectable({
  providedIn: 'root'
})
class MessageTypeService {

  constructor(private logger: NGXLogger, private http: DataService) { }

  getStaffUsers(): Observable<Object> {
    return this.http.get(`/user/staff`);
  }

  getMessageTypes(): Observable<Object> {
    return this.http.get('/messagetype');
  }

  deleteMessageType(id): Observable<Object> {
    return this.http.delete(`/messagetype/delete/${id}`);
  }

  saveMessageType(messageType: MessageType): Observable<Object> {
    return this.http.post(`/messagetype`, messageType);
  }
}

@Injectable({
  providedIn: 'root'
})
class FlagService {

  constructor(private logger: NGXLogger, private http: DataService) { }

  get(request: PaginationRequest): Observable<Object> {
    return this.http.get(`/flag?${PaginationRequest.toQuery(request)}`);
  }

  post(data: Flag): Observable<Object> {
    return this.http.post(`/flag`, data);
  }

  put(data: Flag): Observable<Object> {
    return this.http.put(`/flag/${data.id}`, data);
  }

  delete(id): Observable<Object> {
    return this.http.delete(`/flag/${id}`);
  }
}

@Injectable({
  providedIn: 'root'
})
class FeeTypeService {

  constructor(private logger: NGXLogger, private http: DataService) { }

  get(request: PaginationRequest): Observable<Object> {
    return this.http.get(`/feetype?${PaginationRequest.toQuery(request)}`);
  }

  post(data: FeeType): Observable<Object> {
    return this.http.post(`/feetype`, data);
  }

  put(data: FeeType): Observable<Object> {
    return this.http.put(`/feetype/${data.id}`, data);
  }

  delete(id): Observable<Object> {
    return this.http.delete(`/feetype/${id}`);
  }

  exists(programTypeid, netSuiteCode): Observable<Object> {
    return this.http.get(`/feetype/${programTypeid}/${netSuiteCode}`);
  }
}
@Injectable({
    providedIn:'root'
})
class ChangeReasonsService{
  constructor(private logger: NGXLogger, private http: DataService) { }
  get(request: PaginationRequest): Observable<Object> {
    return this.http.get(`/setting/StatusChangeReason?${PaginationRequest.toQuery(request)}`);
  }

  post(data: StatusChangeReason): Observable<Object> {
    return this.http.post(`/setting/statusChangeReason`, data);
  }

  put(data: StatusChangeReason,id:number): Observable<Object> {
    return this.http.put(`/setting/StatusChangeReason/${id}`, data);
  }
  getLookup(lookupType: string, excluded?: Array<SelectItem>, sort: boolean = true): Observable<LookupItem[]> {
    return this.http.get(`/program/lookup/${lookupType}`).pipe(map((resp: Array<any>) => {
      if (sort) {
        resp.sort((a, b) => (a.label > b.label) ? 1 : -1);
      }
      
      if (!excluded) {
        return resp;
      }

      return resp.filter(x => excluded.filter(y => y.value == x.value).length == 0);
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
class HelpLinkService {

  constructor(private logger: NGXLogger, private http: DataService) { }

  get(request: PaginationRequest): Observable<Object> {
    return this.http.get(`/helplink?${PaginationRequest.toQuery(request)}`);
  }

  post(data: HelpLink): Observable<Object> {
    return this.http.post(`/helplink`, data);
  }

  put(data: HelpLink): Observable<Object> {
    return this.http.put(`/helplink/${data.id}`, data);
  }

  delete(id): Observable<Object> {
    return this.http.delete(`/helplink/${id}`);
  }
}
export {
  MessageTypeService,
  FlagService,
  FeeTypeService,
  ProgramTypeService,
  ChangeReasonsService,
  HelpLinkService
}
