import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  'providedIn': 'root'
})
export class DataService {

  private baseURL: string;
  constructor(private http: HttpClient, private logger: NGXLogger) {
    this.baseURL = env.apiUrl;
  }

  get(url: string) {
    return this.http.get(`${this.baseURL}${url}`);
  }

  getTyped<T>(url: string): Observable<T> {
    return this.http.get<T>(`${this.baseURL}${url}`);
  }

  getFile(url: string) {
    const options = {
      'responseType': 'arraybuffer' as 'json'
    };

    return this.http.get(`${this.baseURL}${url}`, options);
  }

  getFileWithResponse(url: string) {
    const options = {
      'observe': 'response' as const,
      'responseType': 'arraybuffer' as 'json'
    };

    return this.http.get(`${this.baseURL}${url}`, options);
  }

  post(url, data) {
    return this.http.post(`${this.baseURL}${url}`, data);
  }

  delete(url) {
    return this.http.delete(`${this.baseURL}${url}`);
  }

  patch(url, data) {
    return this.http.patch(`${this.baseURL}${url}`, data);
  }

  put(url, data) {
    return this.http.put(`${this.baseURL}${url}`, data);
  }

  options(url) {
    return this.http.options(`${this.baseURL}${url}`);
  }

  jsonp(url, callback) {
    return this.http.jsonp(`${this.baseURL}${url}`, callback);
  }

  request(req) {
    return this.http.request(req);
  }

}
