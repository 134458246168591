import { AuthService } from '@/core/core.barrel';
import { DataService } from '@/core/services/data.service';
import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'ciee-shadow-staff',
  templateUrl: './shadow-staff.component.html',
  styleUrls: ['./shadow-staff.component.scss']
})
export class ShadowStaffComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  useremail: string;
  userId: number;
  userEmails: string[];

  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messenger: MessageService
  ) { }

  ngOnInit(): void {
    this.authService.getUserId().subscribe((userId) => {
      this.userId = userId;
    });
  }

  shadow() {
    this.dataService.post(`/ShadowStaff/${this.useremail}`, null)
      .subscribe(
        (res) => {
          this.ref.close(true);
        },
        (errorResponse) => {
          this.messenger.add({ key: 'toast', severity: 'error', summary: 'Error', detail: errorResponse.error });
        },
      )
  }

  // load user emails except for the current admin user
  loadEmails(event) {
    console.log(event);
    this.dataService.get(`/ShadowStaff/StaffEmails?query=${event.query}`)
      .subscribe(
        (res: any) => {
          this.userEmails = res.body ? res.body : res;
        },
        (errorResponse) => {
          this.messenger.add({ key: 'toast', severity: 'error', summary: 'Error', detail: errorResponse.error });
        },
      )
  }

  cancel() {
    this.ref.close(false);
  }
}
