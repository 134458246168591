import { SelectItem } from "primeng/api";

export class Term {
    id: number;
    name: string;
    isActive: boolean;
    modifiedBy: string;
    displayOrder: number;
    sortOrder?: number;
    modifiedOn: string;
    programTypeIds: number[];
}

export class TermDetail {
    id: number;
    name: string;
    description: string;
    netsuiteCode: string;
    displayOrder: number;
    sortOrder?: number;
    isActive: boolean;
    useFiscalYearForRevenueRecognition: boolean;
    calendarSeason: string;
    groupByCalendarSeason?: boolean;
    billByTermNetsuiteCode: string;
    programTypeIds: string[];
}

export interface TermsStateModel {
    filter: TermsFilter;
    programTypes: SelectItem[],
    terms: TermLookup[]
}


export class TermsFilter {
    name: string;
    isActive: boolean;
    programTypeIds: Array<number>;
    termIds: Array<number>;
    modifiedBy: string;
}

export class TermLookup {
    value: number;
    label: string;
    displayOrder: number;
    sortOrder?: number;
}

export enum TermFilterID {
    Term = 0,
    TermCombination
}

export enum calendarSeason {
    Spring = 1,
    Summer,
    Fall,
    Winter
}

