export class Roles {
  public static readonly administrator = 'Administrator';
  public static readonly staff = 'Staff';
  public static readonly residentStaff = 'Resident Staff';
  public static readonly scholarshipReview = 'Scholarship Reviewer';
  public static readonly scholarshipAdministartor = 'Scholarship Administrator';
  public static readonly finance = 'Finance';
  public static readonly operations = 'Operations';
  public static readonly institutionalRelations = 'Institutional Relations';
}

export enum ApplicationStatuses {
  New = 1,
  Submitted = 2,
  InReview = 3,
  AcceptedConditionally = 4,
  Waitlisted = 5,
  Accepted = 6,
  Confirmed = 7,
  Arrived = 8,
  Completed = 9, 
  Deferred = 10,
  ProgramChange = 11,
  Cancelled = 12,
  Rejected = 13,
  Withdrawn = 14,
  Incomplete = 15,
  Complete = 16,
  ReadyForReview = 17,
  PendingDecision = 18,
  Offered = 19,
  ScholarshipAwarded = 20,
  ScholarshipNotAwarded = 21,
  Removed = 22
}

export enum StatusChangeBasedOns {
  CIEE='CIEE',
  Participant='Participant',
  ThirdParty='Third Partiy'
}

export enum OrganizationTypes {
  'Sending Institution'='1',
  'Direct Enrollment'='0',
}

export class FeatureFlags {
  public static readonly sectionTemplateEditing = 'SectionTemplateEditing';
}