import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ClaimsService } from './claims.service';
import { Router } from '@angular/router';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { environment } from '@env/environment';
import { Observable, from, EMPTY } from 'rxjs';
import { map, switchMap, delay, tap, finalize } from 'rxjs/operators';
import JwtDecode from 'jwt-decode';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngxs/store';
import { SetProgressSpinnerVisiblity } from '../store';

@Injectable({
  'providedIn': 'root'
})

export class AuthService {
  private manager = new UserManager(this.getClientSettings());
  private silentLoginDelay: number = 3000;
  private returnUrlKey: string = 'return_url';

  constructor(
    private claimsService: ClaimsService,
    private router: Router,
    private logger: NGXLogger,
    private store: Store
  ) {
    this.manager.clearStaleState();
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(x => x != null && !x.expired));
  }

  public isRoleAuthorized(allowedRoles?: string[]): Observable<boolean> {
    // if there are no roles listed allow
    return this.getDecodedAccessToken().pipe(map(token => {
      const roles = this.claimsService.getRoles(token);
      if (allowedRoles == null || allowedRoles === undefined || allowedRoles.length === 0) {
        return true;
      }

      return allowedRoles.filter((val) => roles.includes(val)).length > 0;
    }));
  }

  public getUserId(): Observable<number> {
    return this.getDecodedAccessToken().pipe(map(token => {
      return this.claimsService.getUserId(token)
    }));
  }

  public getShadowedUserId(): Observable<number> {
    return this.getDecodedAccessToken().pipe(map(token => {
      return this.claimsService.getShadowedUserId(token)
    }));
  }

  public getShadowedUserName(): Observable<string> {
    return this.getDecodedAccessToken().pipe(map(token => {
      return this.claimsService.getShadowedUserName(token)
    }));
  }

  public login(returnUrl: string): void {
    sessionStorage.setItem(this.returnUrlKey, returnUrl);
    this.manager.signinRedirect();
  }

  public loginSilent(): Observable<boolean> {
    this.store.dispatch(new SetProgressSpinnerVisiblity(true));
    // There is no way to get notified about silent refresh result, so we'll delay further execution instead
    return from(
      this.manager.signinSilent()
    ).pipe(
      tap(() => { this.store.dispatch(new SetProgressSpinnerVisiblity(true)) }),
      delay(this.silentLoginDelay),
      switchMap(() => {
        return this.isAuthenticated()
      }),
      finalize(() => this.store.dispatch(new SetProgressSpinnerVisiblity(false)))
    );
  }

  public logout(): void {
    this.isAuthenticated().subscribe(res => {
      if (res) {
        this.manager.signoutRedirect();
      }
    });
  }

  public completeAuthentication(): void {
    from(this.manager.signinRedirectCallback()).subscribe(() => {
      this.router.navigateByUrl(sessionStorage.getItem(this.returnUrlKey) || '');
    });
  }

  public accessDenied(error: any): void {
    this.router.navigate(["accessdenied"], { state: { data: { error: { status: error.status } } } });
  }

  public setApiAuthHeaders(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.getUser().pipe(map(user => {
      return request.clone({ setHeaders: { Authorization: 'Bearer ' + user.access_token } })
    }));
  }

  public getUser(): Observable<User> {
    return from(this.manager.getUser());
  }

  private getDecodedAccessToken(): Observable<IAccessToken> {
    try {
      return this.getUser().pipe(map(user => JwtDecode(user.access_token) as IAccessToken));
    } catch (err) {
      this.logger.error(err);
      return EMPTY;
    }
  }

  private getClientSettings(): UserManagerSettings {
    return {
      authority: environment.config.authority,
      client_id: environment.config.clientId,
      redirect_uri: environment.config.redirectUri,
      post_logout_redirect_uri: environment.config.postLogoutRedirectUri,
      response_type: "code",
      scope: environment.config.scope,
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.config.silentRedirectUri,
      revokeAccessTokenOnSignout: true
    };
  }
}

export interface IAccessToken {
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': number,
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string,
  oid: string,
  shadowedUserName: string,
  shadowedUserId: number,
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string[],
  Permissions: string,
  nbf: number,
  exp: number,
  ProgramLocations: Array<number>
}
