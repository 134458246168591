import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { ScholarshipStateModel } from './scholarship-state.model';
import { SetNeedRefreshingFundingProgramDetail, SetNeedRefreshingFundingProgram } from './scholarship.actions';

@State<ScholarshipStateModel>({
  'name': 'scholarship',
  'defaults': {
    'needRefreshingFundingProgramDetail': false,
    'needRefreshingFundingProgram': false
  }
})
@Injectable()
export class ScholarshipState {

  @Action(SetNeedRefreshingFundingProgramDetail)
  public setUpdatedFundingProgramDetail(ctx: StateContext<ScholarshipStateModel>, action: SetNeedRefreshingFundingProgramDetail) {
    ctx.patchState({
      'needRefreshingFundingProgramDetail': action.needRefreshingFundingProgramDetail
    });
  }

  @Action(SetNeedRefreshingFundingProgram)
  public setUpdatedFundingProgram(ctx: StateContext<ScholarshipStateModel>, action: SetNeedRefreshingFundingProgram) {
    ctx.patchState({
      'needRefreshingFundingProgram': action.needRefreshingFundingProgram
    });
  }

}
