import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from "@ngxs-labs/select-snapshot";
import { LoggerModule } from "ngx-logger";
import { environment } from "@env/environment";
import { SidebarModule } from 'primeng/sidebar';

import { AppAccessdeniedComponent, AppBreadcrumbComponent, AppErrorComponent, AppFooterComponent, 
    AppLogoComponent, AppMainComponent, AppMenuComponent, AppNotFoundComponent, 
    AppSubMenuComponent, AppTopBarComponent, AuthCallbackComponent, BreadcrumbService, ClaimsService, 
    GlobalErrorHandler, HttpErrorInterceptor, LogOutComponent, TokenInterceptor } from "./core.barrel";
import { SharedModule } from "@/shared/shared.module";

import { ShadowStaffComponent } from './components/shadow-staff/shadow-staff.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ProgressSpinnerInterceptor } from "./interceptors/progress-spinner.interceptor";

@NgModule({
  'declarations': [
    AppNotFoundComponent, LogOutComponent, AppMainComponent, AppMenuComponent,
    AppSubMenuComponent, AppTopBarComponent, AppFooterComponent, AppBreadcrumbComponent,
    AppErrorComponent, AppAccessdeniedComponent, AppLogoComponent, AuthCallbackComponent, ShadowStaffComponent, ProgressSpinnerComponent
  ],
  'imports': [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    SidebarModule,
    HttpClientModule,
    NgxsModule.forRoot([]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    LoggerModule.forRoot(
      {
        'serverLoggingUrl': `${environment.apiUrl}/log`,
        'level': environment.logLevel,
        'serverLogLevel': environment.serverLogLevel,
        'disableConsoleLogging': false,
        'enableSourceMaps': true
      })
  ],
  'exports': [
    AppNotFoundComponent, LogOutComponent, AppMainComponent, AppMenuComponent,
    AppSubMenuComponent, AppTopBarComponent, AppFooterComponent, AppBreadcrumbComponent,
    AppErrorComponent, AppAccessdeniedComponent, AppLogoComponent, AuthCallbackComponent
  ],
  'providers': [
    BreadcrumbService,
    ClaimsService,
    { 'provide': ErrorHandler, 'useClass': GlobalErrorHandler },

    // All Http Interceptors, AKA middleware (kinda)
    { 'provide': HTTP_INTERCEPTORS, 'useClass': TokenInterceptor, 'multi': true },
    { 'provide': HTTP_INTERCEPTORS, 'useClass': HttpErrorInterceptor, 'multi': true },
    { 'provide': HTTP_INTERCEPTORS, 'useClass': ProgressSpinnerInterceptor, 'multi': true },
  ]
})
export class CoreModule { }
