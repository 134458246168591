import { PaginationRequest } from '@/core/core.barrel';
import { DataService } from '@/core/services/data.service';
import { ProgramTypeEditModel } from '@/programs/models/programTypeEditModel';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramTypeService {
  constructor(private logger: NGXLogger, private http: DataService) { }

  get(request: PaginationRequest): Observable<Object>{
    return this.http.get(`/program/programtype?${PaginationRequest.toQuery(request)}`);
  }

  put(model:ProgramTypeEditModel, id:number){
    return this.http.put(`/program/programtype/${id}/edit`, model);
  }

  exists(programTypeid, netSuiteCode): Observable<Object>{
      return this.http.get(`/program/programtype/${programTypeid}/${netSuiteCode}`);
  }

  getProgramTypes() : Observable<Object>{
    return this.http.get('/program/programtype/all');
  }
  
  getProgramType(id:number) : Observable<Object>{
    return this.http.get(`/program/programtype/${id}`);
  }
}