import { Injectable } from '@angular/core';
import { IAccessToken } from "@/core/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  public getClaims(token: IAccessToken): string[] {
    if (!token) {
      throw new Error(`Token is not valid.`);
    }

    const claims = token.Permissions.split(' ');
    return claims ? Array.isArray(claims) ? claims : [claims] : [];
  }

  public getRoles(token: IAccessToken): string[] {
    if (!token) {
      throw new Error(`Token is not valid.`);
    }

    const roles = token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    return roles ? Array.isArray(roles) ? roles : [roles] : [];
  }
  
  public getUserId(token: IAccessToken): number {
    if (!token) {
      throw new Error(`Token is not valid.`);
    }

    return token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  public getShadowedUserId(token: IAccessToken): number {
    if (!token) {
      throw new Error(`Token is not valid.`);
    }

    return token.shadowedUserId;
  }

  public getShadowedUserName(token: IAccessToken): string {
    if (!token) {
      throw new Error(`Token is not valid.`);
    }

    return token.shadowedUserName;
  }
}
