import { CoreState } from '@/core/store/core';
import { CensusState } from '@/census/store';
import { RouterState } from '@/core/store/router';
import { ApplicationPhotoReportState } from '@/reports/store';
import { ScholarshipState } from '@/scholarship/store';
import { SettingsState } from '@/settings/store';
import { AgreementsState } from '@/agreements/store';
import { ProgramsState } from '@/programs/store';
import { AuthState } from './auth';
import { TermsState } from '@/terms/store';
import { HousingState } from '@/housing/store/housing.state';

// Still allow other modules to take what they need, eg action & selectors
export * from '@/core/store/core';
export * from '@/census/store';
export * from '@/core/store/router';

// rolls up our states into one const
export const AppState = [
  AgreementsState,
  ApplicationPhotoReportState,
  CoreState,
  CensusState,
  ProgramsState,
  RouterState,
  ScholarshipState,
  SettingsState,
  AuthState,
  TermsState,
  HousingState
];
