import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: NGXLogger) { }

  handleError(error) {
    const err = {
      'message': error.message ? error.message : error.toString(),
      'stack': error.stack ? error.stack : ''
    };

    this.logger.error(err);
  }
}
