import { LookupItem } from "@/shared/models/shared";
import { toUTCDate } from "@/shared/classes/utils";
import { SelectItem } from "primeng/api";

export class Agreement {
  id: number;
  name: string;
  programType: string;
  programTypeId: number;
  organization: string;
  isActive: boolean;
  organizationId: number;
  creditType: CreditTypes;
  creditTypeLabel: string;
  approvalType: ApprovalTypes;
  approvalTypeLabel: string;
  billingType: BillingTypes;
  billingTypeLabel: string;
  terms: Array<string>;
  termsLabel: string;
  createdBy: string;
  createdOn: Date;

  public static isAgreement(obj: any): obj is Agreement 
  { 
    return (obj as Agreement).programType ? true : false; 
  }
}

export class AgreementEditModel {
  id: number;
  name: string;
  programType: string;
  programTypeId: number;
  organization: string;
  organizationId: number;
  approvalType: ApprovalTypes;
  creditType: CreditTypes;
  isActive: boolean;
  beginYear: number;
  endYear: number;
  isAllOpenCampus: boolean;
  isAllSummer: boolean;
  isAllJanuary: boolean;
  agreementType: AgreementTypes;
  terms: Array<SelectItem> = [];
  programs: Array<LookupItem> = [];
  feeAllocations: Array<FeeAllocation> = [];
}

export class FeeAllocation {
  feeTypeId: number;
  feeTypeName: string;
  billStudent: boolean;
  billSchool: boolean;
  sendToNetsuite: boolean;
}

export interface Organization {
  id: number;
  name: string;
}

export class AgreementProgram {
  id: number;
  agreementId: number;
  programId: number;
  programName: string;
  status: string;
  approvalDate: Date;
  removalDate: Date;

  public static formatDates(model: AgreementProgram) {
    model.approvalDate = toUTCDate(model.approvalDate);
    model.removalDate = toUTCDate(model.removalDate);
    
  }
}

export interface AgreementsStateModel {
  filter: AgreementsFilter;
}

export class AgreementsFilter {
  organizationId: number;
  programTypeId: number;
  seasonId: number;
  year: number;
  isActive: boolean;
  creditType: CreditTypes;
  approvalType: ApprovalTypes;
  billingType: BillingTypes;
}

export enum AgreementTypes {
  AcademicApproval = 1,
  BillingAgreement = 2
}

export const AgreementTypeLabels = new Map<number, string>([
  [AgreementTypes.AcademicApproval, 'academic-approvals'],
  [AgreementTypes.BillingAgreement, 'billing-agreements']
]);

export enum CreditTypes {
  CieeAcademicRecord = 1,
  SchoolOfRecord = 2
}

export const CreditTypeLabels = new Map<number, string>([
  [CreditTypes.CieeAcademicRecord, 'CIEE Academic Record'],
  [CreditTypes.SchoolOfRecord, 'School of Record']
]);

export enum ApprovalTypes {
  ApprovesAll = 1,
  ManagedList = 2,
  ApprovesAllMinusCampus = 3
}

export const ApprovalTypeLabels = new Map<number, string>([
  [ApprovalTypes.ApprovesAll, 'Approves All'],
  [ApprovalTypes.ManagedList, 'Managed List']
]);

export enum BillingTypes {
  BillInstitution = 1,
  BillParticipant = 2,
  BillSplit = 3
}

export const BillingTypeLabels = new Map<number, string>([
  [BillingTypes.BillInstitution, 'Bill Institution'],
  [BillingTypes.BillParticipant, 'Bill Participant'],
  [BillingTypes.BillSplit, 'Bill Split']
]);