import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  'title': 'Polaris',
  'production': true,
  'config': {
    'authority': 'https://applogin.ciee.org/',
    'clientId': 'outbound-portal-client',
    'redirectUri': 'https://portal.ciee.org/auth-callback',
    'silentRedirectUri': 'https://portal.ciee.org/silent-refresh',
    'postLogoutRedirectUri': 'https://portal.ciee.org/logout',
    'scope': 'openid profile outbound:portal-api'
  },
  'apiUrl': 'https://portal-api.ciee.org/api',
  'staffUrl': 'https://my.ciee.org',
  'salesforceApplicationPage': 'https://ciee--c.na121.visual.force.com/apex/PortalRedirect',
  'logLevel': NgxLoggerLevel.TRACE,
  'serverLogLevel': NgxLoggerLevel.ERROR,
  'jiraCollector': null
};
