import { Component, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
  selector: 'fullscreen-shell',
  template: `
  <div class="tw-h-screen">
  <div class="tw-w-3/4 tw-m-auto tw-h-full tw-align-middle tw-flex tw-flex-col tw-justify-center tw-text-center">
    <a [routerLink]="['/']" class="tw-mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 356.95 141.21" class="tw-h-32 ">
        <title>CIEE_Logo</title>
        <path
          d="M94.24,65.22c-2.3-24.35-21-35.59-44.12-35.59C18.47,29.63,1,54.81,1,84.59s14.34,57,49.12,57c23.08,0,41.61-14.56,45.15-40.16H87.36c-2.7,19.56-17.26,33.3-37.24,33.3-27.7,0-41.22-21.22-41.22-50.15C8.9,60.22,23.25,36.5,50.12,36.5c19.33,0,34.75,9.78,36.2,28.72Z"
          transform="translate(-1 -0.4)" style="fill:#005cb9" />
        <rect x="114.6" y="31.73" width="7.9" height="106.98" style="fill:#005cb9" />
        <path
          d="M238.18,86.66c1.46-30.59-13.53-57-47-57-32.25,0-47.65,28.51-47.24,57.86-.41,29.56,16,54.11,47.24,54.11,25.39,0,42.05-13.73,46.2-38.71h-7.9c-3.75,19.78-18.12,31.85-38.3,31.85-26.42,0-39.75-22.89-39.34-48.08ZM151.8,79.79c1.67-21.23,15.61-43.29,39.34-43.29,24.56,0,38.71,20.81,39.14,43.29Z"
          transform="translate(-1 -0.4)" style="fill:#005cb9" />
        <path
          d="M346.14,86.66c1.45-30.59-13.54-57-47-57-32.26,0-47.67,28.51-47.25,57.86-.42,29.56,16,54.11,47.25,54.11,25.4,0,42-13.73,46.21-38.71h-7.92c-3.75,19.78-18.12,31.85-38.29,31.85-26.44,0-39.76-22.89-39.35-48.08Zm-86.39-6.87c1.67-21.23,15.62-43.29,39.35-43.29,24.55,0,38.71,20.81,39.12,43.29Z"
          transform="translate(-1 -0.4)" style="fill:#005cb9" />
        <path
          d="M141.64,2.39A24.27,24.27,0,0,1,119,17.91,24.25,24.25,0,0,1,96.39,2.39l5.17-2a18.71,18.71,0,0,0,17.45,12A18.68,18.68,0,0,0,136.44.4Z"
          transform="translate(-1 -0.4)" style="fill:#ff5f00" />
        <path
          d="M346.86,38.1a5.4,5.4,0,0,1,.74-2.77,5.32,5.32,0,0,1,2-2,5.54,5.54,0,0,1,5.55,0,5.35,5.35,0,0,1,2,2,5.51,5.51,0,0,1,0,5.5,5.54,5.54,0,0,1-2,2,5.55,5.55,0,0,1-8.37-4.77Zm.77,0a4.79,4.79,0,0,0,4.77,4.77,4.6,4.6,0,0,0,2.38-.65,4.69,4.69,0,0,0,1.75-1.73,4.76,4.76,0,0,0,0-4.77A4.84,4.84,0,0,0,354.8,34,4.78,4.78,0,0,0,350,34a4.89,4.89,0,0,0-1.74,1.73A4.65,4.65,0,0,0,347.63,38.1Zm7-1.34a1.77,1.77,0,0,1-.31,1,1.86,1.86,0,0,1-.88.67l1.75,2.91H354l-1.52-2.6h-1v2.6h-1.09V34.82h1.92a2.77,2.77,0,0,1,1.8.48A1.79,1.79,0,0,1,354.65,36.76Zm-3.2,1.11h.8a1.39,1.39,0,0,0,.95-.31,1,1,0,0,0,.35-.77,1,1,0,0,0-.31-.8,1.71,1.71,0,0,0-1-.24h-.78Z"
          transform="translate(-1 -0.4)" style="fill:#005cb9" />
      </svg>
    </a>
    <ng-container *ngTemplateOutlet="incomingTemplate"></ng-container>
  </div>
</div>
  `,
  styles: []
})
export class AppFullScreenShell {
  @ContentChild(TemplateRef) incomingTemplate: TemplateRef<any>;
  @Input() title: Boolean;
  @Input() externalClasses: String;
}

