import { Selector } from "@ngxs/store";
import { TermsStateModel } from "../models/term";
import { TermsState } from "./terms.state";

export class TermsSelectors {
  @Selector([TermsState])
  public static getFilter(state: TermsStateModel) {
    return state.filter;
  }

  @Selector([TermsState])
  public static getTerms(state: TermsStateModel) {
    return state.terms;
  }

  @Selector([TermsState])
  public static getProgramTypes(state: TermsStateModel) {
    return state.programTypes;
  }
}