import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { FeatureFlag } from '../core.barrel';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  private cachedRequest: Observable<FeatureFlag[]>;

  constructor(private http: DataService) { }

  public isEnabled(feature: string): Observable<boolean> {
    return this.getAllFeatureFlags().pipe(
      map((response: FeatureFlag[]) => response.find(x => x.feature == feature)?.isEnabled || false)
    );
  }

  private getAllFeatureFlags(): Observable<FeatureFlag[]> {
    if (!this.cachedRequest) {
      this.cachedRequest = this.http.getTyped<FeatureFlag[]>(`/featureflag`).pipe(
        shareReplay(1)
      );
    }

    return this.cachedRequest;
  }
}
