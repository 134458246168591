import { Component, OnInit, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
  selector: 'ciee-loading',
  template:
  `
    <div *ngIf="this.loading" class="{{externalClasses}}">
      <div class="tw-w-2/4 tw-h-2/4 tw-m-auto tw-flex tw-flex-col tw-justify-center">
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
      </div>
    </div>

    <ng-container *ngIf="!this.loading">
      <ng-container #notLoading *ngTemplateOutlet="incomingTemplate"></ng-container>
    </ng-container>
  `
})
export class LoadingComponent implements OnInit {

  @ContentChild(TemplateRef) incomingTemplate: TemplateRef<any>;
  @Input() loading: Boolean;
  @Input() externalClasses: String;
  constructor() { }

  ngOnInit() {
    this.externalClasses = 'tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center' + ' ' + this.externalClasses;
  }

}

