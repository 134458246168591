import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  template: `
    <fullscreen-shell>
      <ng-template>
        <h1 class="tw-font-bold tw-text-6xl tw-p-8">{{h1}}</h1>
        <h2 class="tw-font-bold tw-text-3xl tw-pb-8">{{h2}}</h2>
        <p>Go to <a [routerLink]="['/']">Home Screen</a> page?</p>
      </ng-template>
    </fullscreen-shell>
`
})
export class AppErrorComponent {
  h1 = "Oops! An Error Has Occurred.";
  h2 = "Something has gone wrong.";

  constructor(private router: Router) {
    const extras = router.getCurrentNavigation().extras;
    if (extras && extras.state && extras.state.data && extras.state.data.errorMessage) {
      this.h2 = extras.state.data.errorMessage;
    }
  }
}
