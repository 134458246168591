<form name="form" (ngSubmit)="shadow()">
    <div>
        <div class="p-fluid p-grid my-3">
            <div class="field p-col-12">
                <p-autoComplete 
                    styleClass="p-autocomplete" 
                    [suggestions]="userEmails"
                    [dropdown]="true"                    
                    [ngModelOptions]="{standalone: true}"
                    [forceSelection]="true"
                    placeholder="Staff Email" 
                    [required]="true" 
                    (completeMethod)="loadEmails($event)"
                    [(ngModel)]="useremail" >
                </p-autoComplete>
            </div>
        </div>
        <div class="mt-4">
            <button type="submit" pButton icon="pi pi-fw pi-check" label="Shadow"
                class="p-button-success mr-3"></button>
            <button type="button" pButton icon="pi pi-fw pi-times" label="Cancel" class="p-button-danger"
                (click)="cancel()"></button>
        </div>
    </div>
</form>