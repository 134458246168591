import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  template: `
    <fullscreen-shell>
      <ng-template>
        <h1 class="tw-font-bold tw-text-5xl tw-p-8">You have been logged out</h1>
        <p>To login again, please click <a [routerLink]="['/']">here</a></p>
      </ng-template>
    <fullscreen-shell>
  `
})
export class LogOutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
