export class ShadowStaff {

    public static readonly type = "[ShadowUser] Set Shadowed Staff Info";

    constructor(public name: string, public id: number) { }
}

export class StopShadowingStaff {

    public static readonly type = "[ShadowUser] Stop Shadowing Staff";
    
}
