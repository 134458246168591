import { Component, OnInit, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment as env } from '@env/environment';

@Component({
  'selector': 'ciee-root',
  'template': `<router-outlet></router-outlet>`,
  'providers': [MessageService]
})
export class AppComponent {
    constructor(private renderer: Renderer2) {}

  ngOnInit() {
    if (env.jiraCollector) {
        const script = this.renderer.createElement('script');
        this.renderer.setAttribute(script, 'src', `${env.jiraCollector}`);    
        this.renderer.appendChild(document.head, script);
    }
  }
}
