import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { AppMainComponent } from './app.main.component';
import { AuthService } from '@/core/services/auth.service';
import { Roles } from '@/shared/classes/constants';

interface NavigationLink {
  label: string;
  icon: string;
  url: string;
  children?: NavigationLink[];
  roles: string[];
}

@Component({
    selector: 'app-menu',
    template: `
        <div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive}" (click)="app.onSidebarClick($event)"
        (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
            <div class="sidebar-logo tw-bg-white">
                <a href="#" class="sidebar-pin" title="Toggle Menu" (click)="app.onToggleMenuClick($event)">
                  <i class="pi pi-lock" *ngIf="app.staticMenuActive"></i>
                  <i class="pi pi-lock-open" *ngIf="!app.staticMenuActive"></i>
                </a>
                <a routerLink="/">
                  <app-logo [toggle]="(app.sidebarActive || app.staticMenuActive)" ></app-logo>
                </a>
            </div>

            <div class="layout-menu-container">
                <ul app-submenu [item]="model" root="true" class="layout-menu" visible="true" [reset]="reset" parentActive="true"></ul>
            </div>
        </div>
    `
})
export class AppMenuComponent implements OnInit {

    @Input() reset: boolean;

    model: any[];

    constructor(public app: AppMainComponent, private authService: AuthService) {}

    ngOnInit() {
      const navigationList: NavigationLink[] =  [
        { 'label': 'Census', 'icon': 'users', 'url': '/census', 'children': [], roles: [Roles.administrator, Roles.staff, Roles.residentStaff] },
        { 'label': 'Reports', 'icon': 'chart-bar', 'url': null, 'children': [
          { 'label': 'Application Photo', 'icon': null, 'url': '/reports/applicationphoto', 'children': [], roles: [] },
          { 'label': 'Forms & Documents', 'icon': null, 'url': '/reports/forms-documents', 'children': [], roles: [] },
          { 'label': 'Program Overview', 'icon': null, 'url': '/reports/program-overview', 'children': [], roles: [] },
          { 'label': 'Emergency Contact List', 'icon': null, 'url': '/reports/emergency-contact-list', 'children': [], roles: [Roles.administrator, Roles.residentStaff] },
          { 'label': 'Emergency Contacts (Home Institutions)', 'icon': null, 'url': '/reports/emergency-contacts-host-institutions', 'children': [], roles: [Roles.administrator, Roles.residentStaff] }],
          roles: [Roles.administrator, Roles.staff, Roles.residentStaff]
        },
        { 'label': 'Scholarship Management', 'icon': 'money-bill', 'url': null, 'children': [
            { 'label': 'Scholarships', 'icon': null, 'url': '/scholarship/scholarships', 'children': [], roles: [Roles.administrator] },
            { 'label': 'Scholarship Reviewer Assignments', 'icon': null, 'url': '/scholarship/assignments', 'children': [], roles: [Roles.administrator, Roles.staff] },
            { 'label': 'Awards and Discounts', 'icon': null, 'url': '/scholarship/awards', 'children': [], roles: [Roles.administrator, Roles.staff] }], 
            roles: [Roles.administrator, Roles.staff, Roles.operations] },
        { 'label': 'Search', 'icon': "search", 'url': null, roles: [], 'children': [
            { 'label': 'Application', 'icon': null, 'url': '/search/applications', 'children': [], roles: [] },
            { 'label': 'Task', 'icon': null, 'url': '/search/tasks', 'children': [], roles: [] },
            { 'label': 'My Saved', 'icon': null, 'url': '/search/saved', 'children': [], roles: [] }]
        },
        { 'label': 'Settings', 'icon': "cog", 'url': null, roles: [Roles.administrator, Roles.operations], 'children': [
            { 'label': 'Message Routing', 'icon': 'inbox', 'url': '/settings/messagerouting', 'children': [], roles: [] },
            { 'label': 'Application Flags', 'icon': 'tag', 'url': '/settings/applicationflags', 'children': [], roles: [] },
            { 'label': 'Fee Types', 'icon': 'credit-card', 'url': '/settings/feetypes', 'children': [], roles: [] },
          { 'label': 'Change Reasons', 'icon': 'sliders-h', 'url': '/settings/changereasons', 'children': [], roles: [Roles.administrator, Roles.operations] },
          { 'label': 'Publishing Status', icon: null, 'url': '/settings/xmlpublishingstatus', roles: [Roles.operations, Roles.administrator], children: [] },
          { 'label': 'Help Links', 'icon': 'pi pi-question-circle', 'url': '/settings/helptext', 'children': [], roles: [] },

            ]
        },
        { 'label': 'Agreements', 'icon': "calendar", 'url': null, roles: [Roles.administrator, Roles.staff, Roles.finance, Roles.institutionalRelations], 'children': [
            { 'label': 'Academic Approvals', 'icon': null, 'url': '/agreements/academicapprovals', 'children': [], roles: [Roles.administrator, Roles.staff, Roles.institutionalRelations] },
            { 'label': 'Billing Agreements', 'icon': null, 'url': '/agreements/billingagreements', 'children': [], roles: [Roles.administrator, Roles.staff, Roles.finance] }
        ]},
        { 'label': 'Program Management', 'icon': "briefcase", 'url': null, roles: [Roles.administrator, Roles.staff, Roles.residentStaff, Roles.operations], 'children': [
            { 'label': 'Programs and Details', 'icon': null, 'url': '/programs/programs', 'children': [], roles: [] },
            { 'label': 'Program Locations', 'icon': null, 'url': '/programs/locations', 'children': [], roles: [Roles.administrator] },
            { 'label': 'Program Types', 'icon': null, 'url': '/programs/programtypes', 'children': [], roles: [Roles.administrator] }
        ]},
        { 'label': 'Term Management', 'icon': "th-large", 'url': '/terms', roles: [Roles.administrator]},
        { 'label': 'Housing', 'icon': "home", 'url': '/housing', roles: [Roles.administrator, Roles.operations, Roles.staff]},
        { 'label': 'Template Management', 'icon': "folder-open", 'url': null, roles: [Roles.administrator, Roles.operations, Roles.staff], 'children': [
            { 'label': 'Template Library', 'icon': null, 'url': '/templates/templatelibrary', 'children': [], roles: [] },
            { 'label': 'Form Fields', 'icon': null, 'url': '/templates/formfields', 'children': [], roles: [Roles.administrator, Roles.operations] },
            { 'label': 'Tasks', 'icon': null, 'url': '/templates/tasks', 'children': [], roles: [Roles.administrator, Roles.operations] }
        ]
        },
        { 'label': 'User Management', 'icon': "users", 'url': '/users/usermanagement', roles: [Roles.administrator] },
      ];

      this.model = navigationList.map(link => this.mapLinkToMenuItem(link));
    }

    mapLinkToMenuItem(link: NavigationLink) {
      let item = {
        'label': link.label,
        'icon': link.icon && link.icon.length > 0 ? 'pi pi-fw pi-' + link.icon : null,
        'routerLink': link.url,
        'style': { 'color': 'white' },
        'items': link.children && link.children.length > 0 ? link.children.map((child: NavigationLink) => this.mapLinkToMenuItem(child)) : null
      };

      item.enabled = link.roles.length == 0;
      if (!item.enabled) {
        this.authService.isRoleAuthorized(link.roles).subscribe(enabled => item.enabled = enabled);
      }

      return item;
    }
}

@Component({
  /* tslint:disable:component-selector */
    selector: '[app-submenu]',
  /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.enabled">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink" (mouseenter)="onMouseEnter(i)"
				   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
					<span class="menuitem-text">{{child.label}}</span>
					<i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
                    <i [ngClass]="child.icon"></i>
                </a>

                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
					<span class="menuitem-text">{{child.label}}</span>
					<i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
                    <i [ngClass]="child.icon"></i>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items && isActive(i)" [visible]="isActive(i)" [reset]="reset"
                    [parentActive]="isActive(i)" [@children]="(app.isHorizontal())&&root ? isActive(i) ?
                     'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: AppMainComponent, public appMenu: AppMenuComponent) {}

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isMobile()) {
                this.app.sidebarActive = false;
                this.app.menuMobileActive = false;
            }

            if (this.app.isHorizontal()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
