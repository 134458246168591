import { State, Action, Selector, StateContext } from '@ngxs/store';
import { RouterAction } from './router.actions';
import { RouterStateModel } from './router-state.model';
import { Injectable } from '@angular/core';

@State<RouterStateModel>({
  'name': 'router',
  'defaults': {
    'items': []
  }
})
@Injectable()
export class RouterState {

  @Selector()
  public static getState(state: RouterStateModel) {
    return state;
  }

  @Action(RouterAction)
  public add(ctx: StateContext<RouterStateModel>, { payload }: RouterAction) {
    const stateModel = ctx.getState();
    stateModel.items = [...stateModel.items, payload];
    ctx.setState(stateModel);
  }
}
