import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { AuthService } from '@/core/services/auth.service';
import { Observable, throwError, TimeoutError, of } from 'rxjs';
import { catchError, timeout, switchMap } from 'rxjs/operators';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Router } from '@angular/router';

const APP_AUTH_XHR_TIMEOUT = 60000;
const APP_REPORT_XHR_TIMEOUT = 300000;

enum AuthorizationTypes {
  Unauthorized,
  Authorized
}
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  reportExportPaths: Array<string> = [ "/reports/applicationphotoexport", "/document/downloadtaskdocuments", "/reports/emergencycontact" ];

  constructor(private authService: AuthService, private router: Router) { }
 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isAuthenticated().pipe(switchMap(isAuthenticated => {
      if (isAuthenticated) {
        return this.authService.setApiAuthHeaders(request).pipe(
          switchMap((req) => next.handle(req).pipe(
            timeout(this.reportExportPaths.filter(x => req.url.includes(x)).length > 0 ? APP_REPORT_XHR_TIMEOUT : APP_AUTH_XHR_TIMEOUT), 
            catchError(error => this.handleError(error, AuthorizationTypes.Authorized)))));
      } else {
        return this.authService.loginSilent().pipe(switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return this.intercept(request, next);
          } else {
            this.authService.login(this.router.url);
            return of(new HttpResponse<any>({status: 401}))
          }
        }));
      }
    }));
  }

  private handleError(error: any, type: AuthorizationTypes) {
    if (error instanceof TimeoutError) {
      const errorMessage = `An '${AuthorizationTypes[type]}' timeout occurred while connecting to the API. Please try again. If the problem persists contact the tech support.`;
      if (type === AuthorizationTypes.Authorized) {
        this.router.navigate(["error"], { state: { data: { errorMessage: errorMessage, error: error } } });
      } else {
        //TODO: Add popup so the user can see message but doesn't leave page
      }
    }
    return throwError(error);
  }
}
