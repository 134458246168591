import { SearchCriteria } from '@/core/models/searchCriteria';
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { ViewApplicationPhotoReport, SetFormsDocumentFilter } from './application-photo.actions';

@State<ApplicationPhotoReportStateModel>({
  'name': 'applicationPhoto',
  'defaults': {
    'programDetails': [],
    'applicationStatuses': [],
    'formsDocumentFilter': []
  }
})
@Injectable()
export class ApplicationPhotoReportState {

  @Action(ViewApplicationPhotoReport)
  public viewApplicationPhotoReport(ctx: StateContext<ApplicationPhotoReportStateModel>, action: ViewApplicationPhotoReport) {
    ctx.patchState({
      'programDetails': action.programDetailIds,
      'applicationStatuses': action.applicationStatusIds
    });
  }

  @Action(SetFormsDocumentFilter)
  public ViewFormsAndDocumentTemplate(ctx: StateContext<ApplicationPhotoReportStateModel>, action: SetFormsDocumentFilter) {
    ctx.patchState({
      formsDocumentFilter: [...action.searchCriteria]
    });
  }
}

export interface ApplicationPhotoReportStateModel {
  programDetails: number[];
  applicationStatuses: number[];
  formsDocumentFilter: SearchCriteria[]
}
