import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';



@Component({
  'selector': 'ciee-not-found',
  template: `
    <fullscreen-shell>
      <ng-template>
        <h1 class="tw-font-bold tw-text-6xl tw-p-8">Oops! Something has gone wrong.</h1>
        <h2 class="tw-font-bold tw-text-3xl tw-pb-8"> Error 404 - Page not found.</h2>
        <p *ngIf="path">Perhaps you meant to go to the <a [routerLink]="path">"{{ path }}"</a> page?</p>
      </ng-template>
    </fullscreen-shell>
  `
})
export class AppNotFoundComponent implements OnInit {
  path: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.pipe(take(1))
      .subscribe((data: { path: string }) => {
        this.path = data.path;
      });
  }
}
