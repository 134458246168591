import { Selector } from '@ngxs/store';
import { ScholarshipState } from './scholarship.state';
import { ScholarshipStateModel } from './scholarship-state.model';


export class ScholarshipSelectors {
  @Selector([ScholarshipState])
  static getNeedRefreshingFundingProgram(state: ScholarshipStateModel) {
    return state.needRefreshingFundingProgram;
  }

  @Selector([ScholarshipState])
  static getNeedRefreshingFundingProgramDetail(state: ScholarshipStateModel) {
    return state.needRefreshingFundingProgramDetail;
  }

  @Selector([ScholarshipState])
  public static getState(state: ScholarshipStateModel) {
    return state;
  }

}
