import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { AuthStateModel, ShadowStaff, StopShadowingStaff } from ".";

@State<AuthStateModel>({
    'name': 'auth',
    'defaults': {
        'shadowedStaffState': {
            'id': null,
            'name': null
        }
    }
})

@Injectable()
export class AuthState {

    @Action(ShadowStaff)
    shadowStaff(ctx: StateContext<AuthStateModel>, action: ShadowStaff) {
        ctx.patchState({
            'shadowedStaffState': {
                'id': action.id,
                'name': action.name
            }
        })
    }

    @Action(StopShadowingStaff)
    stopShadowingStaff(ctx: StateContext<AuthStateModel>, action: StopShadowingStaff) {
        ctx.patchState({
            'shadowedStaffState': {
                'id': null,
                'name': null
            }
        })
    }
}