import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { CensusStateModel } from './census-state.model';
import {
  SetSelectedProgramDetail,
  SetSelectedProgramDetailOnlineOnly,
  SetSelectedProgramDetailDelayedOnsiteStartDate,
  SetSelectedProgramDetailEarlyOnsiteDepartureDate,
  SetSelectedProgramDetailStartDate,
  SetSelectedProgramDetailEndDate } from './census.actions';

@State<CensusStateModel>({
  'name': 'census',
  'defaults': {
    selectedProgramDetail: 0,
    selectedProgramDetailOnlineOnly: false,
    selectedProgramDetailDelayedOnsiteStartDate: null,
    selectedProgramDetailEarlyOnsiteDepartureDate: null,
    selectedProgramDetailStartDate: null,
    selectedProgramDetailEndDate: null
  }
})
@Injectable()
export class CensusState {

  @Action(SetSelectedProgramDetail)
  public setSelectProgramdetail(ctx: StateContext<CensusStateModel>, action: SetSelectedProgramDetail) {
    ctx.patchState({
      selectedProgramDetail: action.id
    });
  }

  @Action(SetSelectedProgramDetailOnlineOnly)
  public setSelectedProgramDetailOnlineOnly (ctx: StateContext<CensusStateModel>, action: SetSelectedProgramDetailOnlineOnly) {
    ctx.patchState({
      selectedProgramDetailOnlineOnly: action.onlineOnly
    });
  }

  @Action(SetSelectedProgramDetailDelayedOnsiteStartDate)
  public setSelectedProgramDetailDelayedOnsiteStartDate (ctx: StateContext<CensusStateModel>, action: SetSelectedProgramDetailDelayedOnsiteStartDate) {
    ctx.patchState({
      selectedProgramDetailDelayedOnsiteStartDate: action.delayedOnsiteStartDate
    });
  }

  @Action(SetSelectedProgramDetailEarlyOnsiteDepartureDate)
  public setSelectedProgramDetailEarlyOnsiteDepartureDate (ctx: StateContext<CensusStateModel>, action: SetSelectedProgramDetailEarlyOnsiteDepartureDate) {
    ctx.patchState({
      selectedProgramDetailEarlyOnsiteDepartureDate: action.earlyOnsiteDepartureDate
    });
  }

  @Action(SetSelectedProgramDetailStartDate)
  public setSelectedProgramDetailStartDate (ctx: StateContext<CensusStateModel>, action: SetSelectedProgramDetailStartDate) {
    ctx.patchState({
      selectedProgramDetailStartDate: action.date
    });
  }

  @Action(SetSelectedProgramDetailEndDate)
  public setSelectedProgramDetailEndDate (ctx: StateContext<CensusStateModel>, action: SetSelectedProgramDetailEndDate) {
    ctx.patchState({
      selectedProgramDetailEndDate: action.date
    });
  }
}
