import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private logger: NGXLogger, private http: DataService) { }

  getUserProgramLocations(): Observable<Object>{
    return this.http.get(`/userprogramlocation`);
  }
}
