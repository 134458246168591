import { Component, Renderer2, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { CoreSelectors } from '@/core/store/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SetAvailableLocations } from '@core/store/core/core.actions';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-main',
  template: `
        <div class="layout-wrapper" (click)="onLayoutClick()"
        [ngClass]="{'layout-horizontal': horizontalMenu,
                    'layout-wrapper-static': staticMenuActive,
                    'layout-rightpanel-active': rightPanelActive,
                    'layout-topbar-mobile-active': topbarMobileMenuActive,
                    'layout-megamenu-mobile-active': megaMenuMobileActive,
                    'layout-sidebar-mobile-active': menuMobileActive}" [class]="menuColor">

            <app-menu [reset]="resetMenu"></app-menu>
            <div class="layout-main">
                <app-topbar></app-topbar>

                <app-breadcrumb></app-breadcrumb>
                <ciee-progress-spinner *ngIf="progressSpinnerVisblity$ | async"></ciee-progress-spinner>

                <div class="layout-content">
                    <router-outlet></router-outlet>
                </div>

                <app-footer></app-footer>

                <div class="layout-main-mask"></div>
                <p-toast></p-toast>
            </div>
        </div>
    `,
  styles: [`
    @media print {
      app-topbar, app-breadcrumb, app-rightpane, app-footer {
        display: none;
      }
      .layout-content {
        padding: 0;
      }
      .layout-main {
        padding: 0;
      }
      .layout-main-mask {
        display: none;
      }      
    }
  `],
  animations: [
    trigger('mask-anim', [
      state('void', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 0.8
      })),
      transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
    ])
  ]
})
export class AppMainComponent implements OnInit {
  @Select(CoreSelectors.getAvailableLocations) userLocations$: Observable<number[]>;
  @Select(CoreSelectors.getProgressSpinnerVisiblity) progressSpinnerVisblity$: Observable<boolean>;
  title = environment.title;
  loggedIn: boolean;
  userProgramLocations: number[];
  public userInfo: any = null;

  horizontalMenu = false;

  darkMode = false;

  menuColor = 'layout-menu-light';

  rightPanelClick: boolean;

  rightPanelActive: boolean;

  menuClick: boolean;

  staticMenuActive: boolean;

  menuMobileActive: boolean;

  megaMenuClick: boolean;

  megaMenuActive: boolean;

  megaMenuMobileClick: boolean;

  megaMenuMobileActive: boolean;

  topbarItemClick: boolean;

  topbarMobileMenuClick: boolean;

  topbarMobileMenuActive: boolean;

  configDialogActive: boolean;

  sidebarActive: boolean;

  activeTopbarItem: any;

  resetMenu: boolean;

  topbarMenuActive: boolean;

  menuHoverActive: boolean;

  constructor(public renderer: Renderer2, private router: Router, private logger: NGXLogger, messenger: MessageService, private authService: AuthService, private userService: UserService) { }

  ngOnInit() {
    
    if (localStorage.getItem('staticMenuActive')) {
      this.staticMenuActive = localStorage.getItem('staticMenuActive') === 'true';
    }

    this.authService.isAuthenticated().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        if (this.userProgramLocations == null) {
          this.userService.getUserProgramLocations().subscribe(res => {
            this.setUserProgramLocations(res);
          });
        }
      }
    })

   }

  @Dispatch() setUserProgramLocations = (upls) => new SetAvailableLocations(upls);

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaMenuClick) {
      this.megaMenuActive = false;
    }

    if (!this.megaMenuMobileClick) {
      this.megaMenuMobileActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal()) {
        this.resetMenu = true;
      }

      if (this.menuMobileActive) {
        this.menuMobileActive = false;
      }

      this.menuHoverActive = false;
    }

    this.menuClick = false;
    this.topbarItemClick = false;
    this.megaMenuClick = false;
    this.megaMenuMobileClick = false;
    this.rightPanelClick = false;
  }


  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;

    event.preventDefault();
  }

  onRightPanelClose(event) {
    this.rightPanelActive = false;
    this.rightPanelClick = false;

    event.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event) {
    this.topbarMobileMenuClick = true;
    this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

    event.preventDefault();
  }


  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isMobile() || this.isTablet()) {
      this.menuMobileActive = !this.menuMobileActive;
    }

    event.preventDefault();
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onToggleMenuClick(event: Event) {
    localStorage.setItem('staticMenuActive', (!this.staticMenuActive).toString())
    this.staticMenuActive = !this.staticMenuActive;
    event.preventDefault();
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isHorizontal() {
    return this.horizontalMenu === true;
  }

  isOverlay() {
    return this.horizontalMenu === false;
  }

  openRightPanel () {
      this.rightPanelActive = true;
      this.rightPanelClick = true;
  }

}
