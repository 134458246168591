import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { environment } from "@env/environment";
import { NgxsModule } from "@ngxs/store";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routes";
import { CoreModule } from "./core/core.module";
import { AppState } from "./core/store";

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    AppRoutes,
    CoreModule,
    NgxsModule.forRoot(AppState, { 'developmentMode': !environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
