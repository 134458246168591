import { AppMainComponent } from './app.main.component';
import { Component } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { PaginationRequest, PaginationResult } from '@/core/core.barrel';
import { AuthService } from '@core/services/auth.service';
import { filter } from 'rxjs/operators';
import { ShadowStaffComponent } from '@/core/components/shadow-staff/shadow-staff.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Roles } from '@/shared/classes/constants';
import { Select, Store } from '@ngxs/store';
import { AuthStateSelectors } from '../store/auth/auth.selectors';
import { Observable } from 'rxjs';
import { ShadowStaff, StopShadowingStaff } from '../store/auth';
import { MessageService } from 'primeng/api';
import { DataService } from '../services/data.service';
import { HelpLinkService } from '@/settings/settings.service';
import { HelpLink } from '../../settings/models/helpLink';

@Component({
  selector: 'app-topbar',
  providers: [DialogService],
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-wrapper">
          <div class="layout-topbar-left">
              <div class="layout-topbar-logo-wrapper">
                  <a href="#" class="layout-topbar-logo">
                      <app-logo [toggle]="false"></app-logo>
                      <span class="app-name">Polaris</span>
                  </a>
              </div>

              <a href="#" class="sidebar-menu-button" (click)="app.onMenuButtonClick($event)">
                  <i class="pi pi-bars"></i>
              </a>

              <a href="#" class="topbar-menu-mobile-button" (click)="app.onTopbarMobileMenuButtonClick($event)">
                  <i class="pi pi-ellipsis-v"></i>
              </a>


          </div>
          <div class="layout-topbar-right fadeInDown">
              <ul class="layout-topbar-actions">
                  <li *ngIf="searchVisible" #search class="search-item topbar-item" [ngClass]="{'active-topmenuitem': app.activeTopbarItem === search}">
                      <a href="#" class="topbar-search-mobile-button" (click)="app.onTopbarItemClick($event,search)">
                          <i class="pi pi-search tw-text-3xl tw-text-base-800"></i>
                      </a>
                      <ul class="search-item-submenu fadeInDown" (click)="app.topbarItemClick = true">
                          <li>
                              <button  class="tw-rounded-l-full tw-rounded-r-none focus:tw-shadow-none" icon="pi pi-search" pButton type="button" (click)="onsearchclick()" ></button>
                              <input class="tw-flex-grow tw-rounded-r-full tw-rounded-l-none focus:tw-shadow-none" type="text" pInputText placeholder="Search..." [(ngModel)]="this.searchQuery" (keydown)="onkeydown($event)">
                          </li>
                      </ul>
                  </li>
                  <!-- Help Button -->
                  <li *ngIf="currentHelpLinks.length == 1">
                    <a [href]='currentHelpLinks[0].helpLinkUrl' target='blank'>                    
                        <i class="pi pi-question-circle"></i>
                    </a>                  
                  </li>                  
                  <li #helpmenu *ngIf="currentHelpLinks.length > 1" class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': app.activeTopbarItem === helpmenu}">                                                        
                    <a href="#" (click)="app.onTopbarItemClick($event,helpmenu)">
                        <i class="pi pi-question-circle"></i>
                    </a>
                    <ul class="profile-item-submenu fadeInDown">                  
                        <li *ngFor="let link of currentHelpLinks" class="layout-submenu-item">                        
                            <a [href]='link.helpLinkUrl' target='blank'>
                                <div class="menu-text">{{link.helpLinkText}}</div>
                            </a>
                        </li>
                    </ul>
                  </li>
                  
                  <!-- Profile Button -->
                  <li #profile class="topbar-item profile-item"
                    [ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}">
                    <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                      <span class="profile-info-wrapper tw-flex">
                        <h3 class="tw-my-auto tw-pr-1 pr-3">
                          <ng-container *ngIf="shadowedStaffName$ | async; else originalUserInfoBlock;">
                            You are shadowing, <b>{{shadowedStaffName$ | async}}</b>
                          </ng-container>
                          <ng-template #originalUserInfoBlock>
                            Hi, {{name}}
                          </ng-template>
                        </h3>
                        <div class="tw-p-1  tw-bg-blue-100 tw-rounded-full">
                          <i class="pi pi-user tw-text-3xl tw-text-base-100"></i>
                        </div>
                      </span>
                    </a>
                    <ul class="profile-item-submenu fadeInDown">
                      <li class="layout-submenu-item">
                        <i class="pi pi-user-edit icon"></i>
                        <a>
                          <div class="menu-text">Profile (Coming Soon)</div>
                        </a>
                      </li>
                      <li class="layout-submenu-item">
                      <i class="pi pi-sliders-h icon"></i>
                      <a>
                        <div class="menu-text">Settings (Coming Soon)</div>
                      </a>
                      </li>
                      <li *ngIf="isShadowUserApplicable && !(shadowedStaffName$ | async); else stopShadowingMenu;" class="layout-submenu-item" (click) = "shadowStaff()">
                        <i class="pi pi-link icon"></i>
                        <a >
                          <div class="menu-text">Shadow Staff </div>
                        </a>
                      </li>
                      
                      <ng-template #stopShadowingMenu>  
                        <li *ngIf="shadowedStaffName$ | async" class="layout-submenu-item" (click) = "stopShadowingStaff()">
                          <i class="pi pi-link icon"></i>                        
                          <a>
                            <div class="menu-text">Stop Shadowing</div>
                          </a>
                        </li>
                      </ng-template>
                      <li class="layout-submenu-footer">
                        <p-button class="tw-rounded tw-text-white tw-bg-blue-100" icon="pi pi pi-external-link" (click)="this.auth.logout()" label="Sign Out"></p-button>
                      </li>
                    </ul>
                  </li>
                  <!-- Right Panel Button -->
                  <li *ngIf="rightPanelVisible">
                      <a href="#" class="layout-rightpanel-button" (click)="app.onRightPanelButtonClick($event)">
                          <i class="pi pi-arrow-left"></i>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
    </div>
    `
})
export class AppTopBarComponent {

  activeItem: number;
  searchQuery: string;
  name: string;
  userId: number;
  searchVisible: boolean = false;
  rightPanelVisible: boolean = false;

  isShadowUserApplicable: boolean = false;
  shadowedUserId: number = undefined;
  helpLinks: HelpLink[] = [];
  isHelpLinkVisible: boolean = false;
  currentHelpLinks: HelpLink[] = [];
  sidebarHelpLinkVisible: boolean = false;

  @Select(AuthStateSelectors.getShadowedUserName) shadowedStaffName$: Observable<string>;

  constructor(
    public app: AppMainComponent,
    public auth: AuthService,
    private dataService: DataService,
    private router: Router,
    private dialogService: DialogService,
    private store: Store,
    private messenger: MessageService,
    private helpLinkservice: HelpLinkService
  ) {

    const searchEnabledRoutes = ['/search/applications', '/search/tasks', '/scholarship/awards'];
    const rightPanelEnabledRoutes = searchEnabledRoutes;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {
      const path = location.pathname;
      this.checkHelpLink(path);
      this.searchVisible = searchEnabledRoutes.includes(path);
      this.rightPanelVisible = rightPanelEnabledRoutes.includes(path);
    });
  }

  ngOnInit() {
    this.auth.getUser().subscribe(user => {
      this.name = user.profile.name.substr(0, user.profile.name.indexOf(" "))
      this.userId = 1;
    });

    this.auth.getShadowedUserName().subscribe(
      name => {
        this.store.dispatch(new ShadowStaff(name, null));
      },
    );

    this.auth.getShadowedUserId().subscribe((id) => {
      this.shadowedUserId = id;
    });

    this.checkForAdmin();
    this.loadHelpLinks();   
  }

  loadHelpLinks() {
    let request = new PaginationRequest();
    this.helpLinkservice.get(request).subscribe((res: PaginationResult<HelpLink>) => {
      this.helpLinks = res.data || [];
      this.checkHelpLink(this.router.url);
    });
  }
  checkHelpLink(currentLink) {
    this.currentHelpLinks = this.helpLinks.filter(x => x.portalUrl == currentLink);
       
  } 

  onkeydown(event) {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      this.search();
    }
  };

  onsearchclick() {
    this.search();
  }

  mobileMegaMenuItemClick(index) {
    this.app.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  search() {
    this.router.navigate(["/search"], { queryParams: { q: encodeURIComponent(this.searchQuery.replace(/"/g, '\\"')) } });
  }

  shadowStaff() {
    const ref = this.dialogService.open(ShadowStaffComponent, {
      header: 'Shadow Staff',
      width: '60%'
    });

    ref.onClose.subscribe((result: boolean) => {
      if (result) {
        this.auth.loginSilent().subscribe(
          (res) => {
            this.router.navigateByUrl('/').then(() => {
              window.location.reload();
            });
          },
          () => {
            this.messenger.add({ key: 'toast', severity: 'error', summary: 'Error', detail: `Silent refersh failed!` });
          });
      }
    });
  }

  stopShadowingStaff() {
    this.dataService.put(`/shadowstaff/stop/${this.shadowedUserId}`, null).subscribe(
      (res) => {
        this.auth.loginSilent().subscribe((_) => {
          this.store.dispatch(new StopShadowingStaff());          
          this.router.navigateByUrl('/').then(() => {
            window.location.reload();
          })
        });
      },
      (errorResponse) => {
        this.reportError(errorResponse)
      }
    )
  }

  checkForAdmin() {
    this.auth.isRoleAuthorized([Roles.administrator]).subscribe(res => {
      this.isShadowUserApplicable = res;
    });
  }

  reportError(response) {
    if (response.status == 400) {
      response.error.forEach(err => this.messenger.add({ severity: 'error', summary: 'Error', detail: err }));
    }
  }
}
