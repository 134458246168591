import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { AgreementsFilter, AgreementsStateModel } from '../models/agreement';
import { SetSelectedFilter } from './agreements.actions';

@State<AgreementsStateModel>({
  'name': 'agreements',
  'defaults': {
    filter: new AgreementsFilter()
  }
})
@Injectable()
export class AgreementsState {

  @Action(SetSelectedFilter)
  public setSelectedFilter(ctx: StateContext<AgreementsStateModel>, action: SetSelectedFilter) {
    ctx.patchState({
      filter: action.filter
    });
  }
}
