import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { TermsFilter, TermsStateModel } from "../models/term";
import { SetProgramTypes, SetTerms, SetTermsFilter } from "./terms.actions";

@State<TermsStateModel>({
  'name': 'terms',
  'defaults': {
    filter: new TermsFilter(),
    programTypes: [],
    terms: []
  }
})

@Injectable()
export class TermsState {

  @Action(SetTermsFilter)
  public SetTermsFilter(ctx: StateContext<TermsStateModel>, action: SetTermsFilter) {
    ctx.patchState({
      filter: action.filter
    });
  }

  @Action(SetTerms)
  public SetTerms(ctx: StateContext<TermsStateModel>, action: SetTerms) {
    ctx.patchState({
      terms: action.filter
    });
  }

  @Action(SetProgramTypes)
  public SetProgramTypes(ctx: StateContext<TermsStateModel>, action: SetProgramTypes) {
    ctx.patchState({
      programTypes: action.filter
    });
  }
}
