import { Selector } from '@ngxs/store';
import { CoreState } from './core.state';
import { CoreStateModel} from './core-state.model';

export class CoreSelectors {
  @Selector([CoreState])
  static getUser(state: CoreStateModel) {
    return state.user;
  }

  @Selector([CoreState])
  static getUserId(state: CoreStateModel) {
    return state.user.id;
  }

  @Selector([CoreState])
  static getAvailableLocations(state: CoreStateModel) {
    return state.availableProgramLocationsIds;
  }
  
  @Selector([CoreState])
  static getProgressSpinnerVisiblity(state: CoreStateModel) {
    return state.showProgressSpinner;
  }
}
