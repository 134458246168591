import { SelectItem } from 'primeng/api';

export class HousingTypeDetail {
  id?: number;
  name: string;
  abbreviation: string;
  description: string;
  programLocationId: number = null;
  housingLevelTypeId: number;
}

export class HousingLevelDetail {
  id?: number;
  levelType: HousingLevelType;
  label: string;
  description: string;
  fee: string;
  programLocationId: number = null;
  isActive: boolean = true;
}

export class HousingFilter {
  programTypeId: number;
  programLocationId: number;
  includeInactive: boolean;
}

export class HousingRankingEdit {
  programLocationId: number;
  housingRankingId: number;
  housingRankingOrder: HousingRankingOrder[]
  isActive: boolean = true;
}

export class HousingLevelListEdit {
  housingLevelListId: number;
  programLocationId: number;
  housingLevelId: number;
  housingLevelListItems: HousingLevelListItem[]
  isActive: boolean = true;
}

export class HousingLevelListItem {
  housingLevelId: number;
  order: number;
}

export class HousingRankingOrder {
  housingTypeId: number;
  order: number;
}

export interface HousingStateModel {
  filter: HousingFilter;
  housingTypes: SelectItem[];
  housingLevels: SelectItem[];
  housingLevelLists: SelectItem[];
  reloadHousingType: boolean;
  reloadHousingLevel: boolean;
  reloadHousingLevelList: boolean;
  reloadHousingRankingList: boolean;
}

export enum HousingLevelType {
  Standard = 1,
  Select = 2,
  SelectPlus = 3
}
