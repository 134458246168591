import { SelectItem } from "primeng/api";
import { TermLookup, TermsFilter } from "../models/term";


export class SetTermsFilter {
    public static readonly type = '[Terms] SetSelectedFilter';
    constructor(public filter: TermsFilter) { }
}

export class SetTerms {
  public static readonly type = '[Terms] SetTerms';
  constructor(public filter: TermLookup[]) { }
}

export class SetProgramTypes {
  public static readonly type = '[Terms] SetProgramTypes';
  constructor(public filter: SelectItem[]) { }
}
  