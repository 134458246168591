import { State, Action, StateContext } from '@ngxs/store';
import { SetAvailableLocations, SetProgressSpinnerVisiblity } from './core.actions';
import { CoreStateModel } from './core-state.model';
import { Injectable } from '@angular/core';

@State<CoreStateModel>({
  'name': 'core',
  'defaults': {
    'availableProgramLocationsIds': [],
    'user': {
      'id': '',
      'firstName': '',
      'lastName': '',
      'imageUrl': ''
    },
    'showProgressSpinner': false
  }
})
@Injectable()
export class CoreState {

  @Action(SetAvailableLocations)
  public setAvailableLocations(ctx: StateContext<CoreStateModel>, action: SetAvailableLocations) {
    ctx.patchState({
      'availableProgramLocationsIds': action.locations
    });
  }

  @Action(SetProgressSpinnerVisiblity)
  public setProgressSpinnerVisiblity(ctx: StateContext<CoreStateModel>, action: SetProgressSpinnerVisiblity) {
    ctx.patchState({
      'showProgressSpinner': action.showSpinner
    });
  }
}
