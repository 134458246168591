import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
import { AppAccessdeniedComponent, AppErrorComponent, AppMainComponent, AppNotFoundComponent, AuthCallbackComponent, LogOutComponent } from './core/core.barrel';
import { Roles } from './shared/classes/constants';
import { PathResolveService } from './core/services/path-resolve.service';

export const routes: Routes = [
  {
    path: '', component: AppMainComponent,'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.residentStaff, Roles.operations, Roles.finance, Roles.institutionalRelations] },
    children: [
      { 'path': 'census', 'loadChildren': () => import('@/census/census.module').then(m => m.CensusModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.residentStaff] } },
      { 'path': 'reports', 'loadChildren': () => import('@/reports/reports.module').then(m => m.ReportsModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.residentStaff] } },
      { 'path': 'scholarship', 'loadChildren': () => import('@/scholarship/scholarship.module').then(m => m.ScholarshipModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.residentStaff] } },
      { 'path': 'search', 'loadChildren': () => import('@/search/search.module').then(m => m.SearchModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.residentStaff] } },
      { 'path': 'settings', 'loadChildren': () => import('@/settings/settings.module').then(m => m.SettingsModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.operations] } }, 
      { 'path': 'agreements', 'loadChildren': () => import('@/agreements/agreements.module').then(m => m.AgreementsModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.finance, Roles.institutionalRelations] } },
      { 'path': 'programs', 'loadChildren': () => import('@/programs/programs.module').then(m => m.ProgramsModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.residentStaff, Roles.operations] } },
      { 'path': 'terms', 'loadChildren': () => import('@/terms/terms.module').then(m => m.TermsModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator] } },
      { 'path': 'housing', 'loadChildren': () => import('@/housing/housing.module').then(m => m.HousingModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.operations] } },
      { 'path': 'templates', 'loadChildren': () => import('@/templates/templates.module').then(m => m.TemplatesModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator, Roles.staff, Roles.operations] } },
      { 'path': 'users', 'loadChildren': () => import('@/users/users.module').then(m => m.UsersModule), 'canActivate': [AuthGuard], 'data': { 'allowedRoles': [Roles.administrator] } },
    ]
  },
  { 'path': 'auth-callback', component: AuthCallbackComponent },
  { 'path': 'error', component: AppErrorComponent },
  { 'path': 'accessdenied', component: AppAccessdeniedComponent },
  { 'path': 'notfound', component: AppNotFoundComponent },
  { 'path': 'logout', 'component': LogOutComponent },
  { 'path': '**', 'resolve': { 'path': PathResolveService }, 'component': AppNotFoundComponent},

  { path: '**', redirectTo: '/notfound' },
];

// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
@NgModule({
  'imports': [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  'exports': [RouterModule],
  'providers': [AuthGuard]
})
export class AppRoutes { }
