import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accessdenied',
  template: `
    <fullscreen-shell>
      <ng-template>
        <h1 class="tw-font-bold tw-text-6xl tw-p-8">{{h1}}</h1>
        <h2 class="tw-font-bold tw-text-3xl tw-pb-8">{{h2}}</h2>
        <p>Go to <a [routerLink]="['/']">Home Screen</a> page?</p>
      </ng-template>
    </fullscreen-shell>
`
})
export class AppAccessdeniedComponent {
  h1 : string;
  h2 : string;

  constructor(private router: Router){
    let code  = '';
   
    const extras = router.getCurrentNavigation().extras;
    if (extras && extras.state && extras.state.data && extras.state.data.error) {
      code = extras.state.data.error.status;
    }
    this.h1 = 'Oops! Access Denied.';
    this.h2 = `Error ${code} - You do not have the necessary permissions. Please contact management.`;
  }
}
