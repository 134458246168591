import { SetHousingLevels, SetHousingTypes, SetReloadHousingLevelData, SetReloadHousingLevelListData, SetReloadHousingRankingListData, SetReloadHousingTypeData } from '@/housing/store/housing.actions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { HousingFilter, HousingStateModel } from './../models/housing';
import { SetFilter } from './housing.actions';


@State<HousingStateModel>({
  'name': 'housing',
  'defaults': {
    filter: new HousingFilter(),
    housingTypes: [],
    housingLevels: [],
    housingLevelLists: [],
    reloadHousingType: false,
    reloadHousingLevel: false,
    reloadHousingLevelList: false,
    reloadHousingRankingList: false
  }
})

@Injectable()
export class HousingState {

  @Action(SetFilter)
  public setSelectedFilter(ctx: StateContext<HousingStateModel>, action: SetFilter) {
    ctx.patchState({
      filter: action.filter
    });
  }

  @Action(SetHousingTypes)
  public setHousingTypes(ctx: StateContext<HousingStateModel>, action: SetHousingTypes) {
    ctx.patchState({
      housingTypes: action.data
    });
  }


  @Action(SetHousingLevels)
  public setHousingLevels(ctx: StateContext<HousingStateModel>, action: SetHousingLevels) {
    ctx.patchState({
      housingLevels: action.data
    });
  }

  @Action(SetReloadHousingTypeData)
  public setReloadHousingTypeData(ctx: StateContext<HousingStateModel>, action: SetReloadHousingTypeData) {
    ctx.patchState({
      reloadHousingType: !ctx.getState().reloadHousingType
    });
  }

  @Action(SetReloadHousingLevelData)
  public setReloadHousingLevelData(ctx: StateContext<HousingStateModel>, action: SetReloadHousingLevelData) {
    ctx.patchState({
      reloadHousingLevel: !ctx.getState().reloadHousingLevel
    });
  }

  @Action(SetReloadHousingLevelListData)
  public setReloadHousingLevelListData(ctx: StateContext<HousingStateModel>, action: SetReloadHousingLevelListData) {
    ctx.patchState({
      reloadHousingLevelList: !ctx.getState().reloadHousingLevelList
    });
  }

  @Action(SetReloadHousingRankingListData)
  public setReloadHousingRankingListData(ctx: StateContext<HousingStateModel>, action: SetReloadHousingRankingListData) {
    ctx.patchState({
      reloadHousingRankingList: !ctx.getState().reloadHousingRankingList
    });
  }
}
